// Here you can add other styles
@font-face {
  font-family: 'heroregular';
  src: url('../fonts/hero-regular-webfont.eot');
  src: url('../fonts//hero-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/hero-regular-webfont.woff2') format('woff2'),
    url('../fonts/hero-regular-webfont.woff') format('woff'),
    url('../fonts/hero-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MoonTime';
  src: url('../fonts/MoonTime-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.form-login {
  margin-top: '100px';
}

.fs-7 {
  font-size: 0.875rem !important;
}

.fs-8 {
  font-size: 0.75rem !important;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.full-width-child-resizable-box-print-only {
  .resizable-box {
    @media print {
      width: 100% !important;
      overflow: unset !important;
      padding-top: 30px !important;
    }
  }
}

// @page {
//   size: A4;
//   margin: 0.01%;
//   // @top-center {
//   //   content: "Page " counter(page);
//   // }
// }

.hide-on-print-only{
  @media print {
    display: none !important;
  }
}

:root {
  --clifford: #da373d;
  --signUpBg: #f0f2f5;
  --aumhumcol: #7255b0;
  --bgDark: #1e1f23;
  --signUpbgDark: #140445;
  --nav: #001128;
  --bordercolor: #3dadb8;
  --cardLight: #ebf1ff;
  --cardDark: #383838;
  --goals: #200769;
  --buttonCol: #624de3;
  --buttonColDark: #00267a;
  --tableCol: #1d1e42;
  --row1: #ffffff;
  --row1Dark: #26264f;
  --row2: #f7f6fe;
  --row2Dark: #1d1e42;
  --textHeading: #5b38a8;
  --iconCol: #330cac;
  --tableColDark: #1d1e42;
  --sideBar: #e3ebff;
  --sideBarDark: #000000;
  --tableHoverDark: #17173ffd;
  --tableHoverLight: #685cb431;
  --secondary-body-bg-light: #efd9ce;
  --secondary-body-bg-dark: #14080e;
  --cui-sidebar-nav-link-active-bg: #fff;
  --cui-sidebar-nav-link-color: #fff;
  --cui-sidebar-nav-link-hover-bg: #ffffff36;
  --cui-sidebar-nav-link-hover-color: #fff;
  --cui-sidebar-nav-link-active-color: #5f2ec7;
}

.Toastify__toast-body {
  font-family: 'Outfit';
  font-size: 16px;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: none;
  background: none;
}
.custom-pointer {
  cursor: pointer;
}
