.accordion-button {
    background-color: #FAFBFF !important;
    font-weight: 600;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    color: #212529;
    font-weight: 600;
    font-size: 16px;
    background-color: #D8E6FF !important;
}

.accordion-body {
    background-color: #EEF4FF !important;
    padding: 1% 5% 1% 3%;
}