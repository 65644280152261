.filepond--root,
.filepond--root .filepond--drop-label {
  height: 100% !important;
}

.filepond--wrapper {
    height: 100%;
}

.filepond--item {
    height: 100% !important;
}