.react-calendar {
    /* overrides on default */
    border-radius: 8px;
    /* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); */
    font-family: Outfit, Arial, Helvetica, sans-serif;
    background-color: #ffffff;
    color: #222;
    width: 100%;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}

.react-calendar__navigation button {
    color: #000;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
}

.react-calendar__month-view__days__day--weekend {
    /* color: #d10000; */
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    font-weight: bold;
    line-height: 16px;
}

.react-calendar__tile:disabled {
    /* background-color: #f0f0f0; */
    color: #bbbbbb;
    font-weight: normal;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__tile--now {
    /* background: #ffff76; */
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    /* background: #ffffa9; */
}

.react-calendar__tile--hasActive {
    /* background: #76baff; */
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    /* background: #a9d4ff; */
}

.react-calendar__tile--active {
    color: #006edc;
    background: white;
    font-weight: 700;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #FFF;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}